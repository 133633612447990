<template>
  <div class="bg-rust-75 p-10 !text-charcoal-100">
    <!-- Netlify Form -->
    <div v-if="!formSubmitted">
      <div class="grid items-center gap-8 md:grid-cols-4">
        <h2 class="col-span-4 text-header1 md:text-desktop-header1 xl:col-span-1">
          Hear from us
        </h2>
        <p class="col-span-4 xl:col-span-3">
          Sign up to receive the latest news on our events, exhibitions and exclusive offers.
        </p>
      </div>

      <!-- Changes to this form need to be reflected on the Static Version under /public/forms/newsletter.html -->
      <form
        class="mt-10"
        name="Newsletter"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        @submit.prevent="handleSubmit"
      >
        <div class="grid items-center gap-8 md:grid-cols-4">
          <input
            name="form-name"
            value="Newsletter"
            type="hidden"
          />

          <p class="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <div>
            <input
              class="w-full appearance-none border-0 border-b-2 border-charcoal-100/50 bg-transparent p-3 leading-tight placeholder:text-charcoal-100/50 focus:border-charcoal-100 focus:outline-none"
              name="first_name"
              placeholder="First Name"
              type="text"
              autocomplete="off"
              required
            />
          </div>
          <div>
            <input
              class="w-full appearance-none border-0 border-b-2 border-charcoal-100/50 bg-transparent p-3 leading-tight placeholder:text-charcoal-100/50 focus:border-charcoal-100 focus:outline-none"
              name="last_name"
              placeholder="Last Name"
              type="text"
              autocomplete="off"
              required
            />
          </div>
          <div class="md:col-span-2 md:flex md:justify-between">
            <input
              class="inline-block w-full appearance-none border-0 border-b-2 border-charcoal-100/50 bg-transparent p-3 leading-tight placeholder:text-charcoal-100/50 focus:border-charcoal-100 focus:outline-none md:w-3/4"
              name="email"
              placeholder="Email"
              type="text"
              autocomplete="off"
              required
            />

            <button
              class="mt-10 block w-full rounded-sm border border-charcoal-100 bg-charcoal-100 px-3.5 py-1.5 text-body-copy text-white transition-colors hover:bg-charcoal-75 md:ml-4 md:mt-0 md:inline-block md:w-auto"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- Thank you Message -->
    <div v-else>
      <div class="grid items-center gap-8 md:grid-cols-4">
        <h2 class="text-header1 md:text-desktop-header1 ">
          Thank you
        </h2>
        <p>Your support means everything. We will keep you posted on our latest events, exhibitions and exclusive offers.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const formSubmitted = ref(false);

const handleSubmit = (event: Event) => {
  event.preventDefault();

  const form = event.target as HTMLFormElement;
  const formData = new FormData(form);
  const formObject = Object.fromEntries(formData.entries());

  // Post to static asset - https://answers.netlify.com/t/support-guide-form-problems-form-debugging-404-when-submitting/92
  fetch("/forms/newsletter.html", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formObject as never).toString(),
  })
    .then(() => {
      formSubmitted.value = true;
    })
    .catch((error: unknown) => {
      alert(error);
    });
};
</script>
